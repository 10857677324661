@use 'const';
@use 'designSystem';
@use 'responsive';
@use 'theme';

.hero {
  @include theme.apply(background-color, neutral-white);

  &.fullwidth {
    background-color: transparent;

    .container {
      padding: const.$margin * 3 const.$margin const.$margin;

      @include responsive.mobile {
        padding: const.$margin * 2 const.$margin const.$margin;
      }

      .content {
        grid-template-columns: 1fr;

        @include responsive.mobile {
          grid-template-columns: 1fr;
          grid-template-rows: repeat(2, auto);
          padding: 0;
          row-gap: 24px;
        }

        p {
          @include designSystem.textStyleBodyLarge30;

          @include responsive.mobile {
            @include designSystem.textStyleBodyLarge24;
          }
        }

        .slotsWrapper {
          padding: 0;

          @include responsive.mobile {
            padding: 0;

            * {
              text-align: left;
            }
          }
        }
      }
    }
  }

  &.fullImageStyle {
    position: relative;

    .container {
      padding: const.$margin * 4 const.$margin;

      @include responsive.ltXlDesktop {
        padding: 80px const.$margin;
      }

      @include responsive.ltDesktop {
        padding: 80px const.$margin;
      }

      @include responsive.mobile {
        padding: const.$margin;
      }

      .content {
        grid-template-columns: repeat(2, 50%);

        @include responsive.mobile {
          grid-template-columns: 1fr;
        }

        .slotsWrapper {
          padding: 0 96px 0 0;

          @include responsive.ltXlDesktop {
            padding: 0 80px 0 0;
          }

          @include responsive.ltLargeDesktop {
            padding: 0 80px 0 0;
          }

          @include responsive.ltDesktop {
            padding: 0 40px 0 0;
          }

          @include responsive.mobile {
            padding: 0;
          }
        }
      }
    }

    .fullImage {
      @include responsive.gtMobile {
        position: absolute;
        top: 0;
        left: calc(50% + 12px);
        width: 50%;
        height: 100%;
        max-width: 800px;

        img {
          object-fit: cover;
          object-position: center left;
        }
      }

      @include responsive.mobile {
        .image {
          border-radius: const.$blockBorderRadius;
          aspect-ratio: 4 / 3;
          object-fit: cover;
          height: auto;
        }
      }
    }
  }

  .container {
    max-width: const.$contentMaxWidth;
    margin: 0 auto;
    padding: const.$margin * 4 const.$margin;

    @include responsive.mobile {
      padding: const.$margin * 2 const.$margin;
    }

    .content {
      border-radius: const.$blockBorderRadius;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      align-items: center;

      @include responsive.mobile {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, auto);
        padding: 0;
        row-gap: 24px;
        border-radius: 0;
      }

      .image {
        border-radius: const.$blockBorderRadius;
        height: 100%;
        aspect-ratio: 4 / 3;
        object-fit: cover;

        @include responsive.mobile {
          height: auto;
        }
      }

      .slotsWrapper {
        padding: 64px;

        @include responsive.mobile {
          padding: 0;
        }
      }
    }
  }
}
