@use 'designSystem';
@use 'responsive';
@use 'const';
@use 'theme';

@use 'sass:list';
@use 'sass:meta';
@use 'sass:math';
@use 'sass:map';

@mixin cleanButton {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  color: designSystem.$colorPrimaryDark;
}

@function getcolor($color) {
  @if meta.type-of($color) == color {
    @return $color;
  } @else {
    @return var(--colour-#{$color});
  }
}

$svgSizes: (
  '4XS': 1px,
  '3XS': 2px,
  '2XS': 4px,
  'XS': 8px,
  'S': 12px,
  'M': 16px,
  'L': 24px,
  'XL': 32px,
  '2XL': 40px,
  '3XL': 48px,
  '4XL': 56px,
  '5XL': 64px,
  '6XL': 72px,
  '7XL': 80px,
  '8XL': 92px,
);

@mixin svgFillColor($color) {
  svg {
    g[fill]:not([fill='none']) {
      path {
        fill: getcolor($color);
      }
    }

    g text {
      fill: getcolor($color);
    }

    > path[fill]:not([fill='none']),
    > rect[fill]:not([fill='none']),
    > text[fill]:not([fill='none']),
    > circle[fill]:not([fill='none']) {
      fill: getcolor($color);
    }
  }
}

@mixin svgStrokeColor($color) {
  svg {
    g[stroke]:not([stroke='none']) {
      path {
        stroke: getcolor($color);
      }
    }

    > path[stroke]:not([stroke='none']),
    > rect[stroke]:not([stroke='none']),
    > text[stroke]:not([stroke='none']),
    > circle[stroke]:not([stroke='none']) {
      stroke: getcolor($color);
    }
  }
}

@mixin svgColor($color: designSystem.$colorPrimaryBrand) {
  @include svgFillColor($color);
  @include svgStrokeColor($color);
}

@function getsvgsize($size) {
  @return map.get($svgSizes, $size);
}

@mixin svgSize($size: 'M') {
  svg {
    width: getsvgsize($size);
    height: getsvgsize($size);
  }
}

@mixin containerLayout($padding: const.$contentPadding, $paddingMobile: const.$marginMobile) {
  max-width: const.$contentMaxWidth;
  margin: auto;
  padding: $padding;

  @if $padding == const.$contentPadding {
    @include responsive.ltXlDesktop {
      padding-left: const.$margin;
      padding-right: const.$margin;
    }
  }

  @include responsive.mobile {
    max-width: 100vw;
    margin: 0;
  }
}

@mixin iconButtonStyle($size: const.$iconButtonSize) {
  display: flex;
  width: $size;
  height: $size;
  padding: 0;
  border: none;
  border-radius: 50%;
  z-index: 2;
  justify-content: center;
  align-items: center;

  @include theme.apply(background-color, action-lightest);
  @include theme.apply(color, action-darken);
  @include svgColor(action-darken);

  @include responsive.desktop {
    &:hover {
      @include theme.apply(background-color, action-darken);
      @include theme.apply(color, action-on-action);
      @include svgColor(action-on-action);
    }
  }

  @include responsive.mobile {
    width: const.$smallIconSize;
    height: const.$smallIconSize;
  }
}

@mixin readmoreButtonStyle {
  @include theme.apply(color, action-default);
  @include theme.apply(font-weight, heavy-weight);
  @include designSystem.textStyleHeadingSmall14;

  margin-top: const.$margin * 0.5;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  text-decoration: none;
  cursor: pointer;

  &:active,
  &:focus {
    @include theme.apply(color, action-default);
  }
}

@mixin modalOverlayStyle {
  position: fixed;
  z-index: 10;
  inset: 0;
}

@mixin columnBoxContent {
  padding: 64px;
  border-radius: const.$blockBorderRadius;
  overflow: hidden;

  @include responsive.ltXlDesktop {
    padding: const.$margin * 2;
  }

  @include responsive.ltDesktop {
    padding: const.$margin * 2 const.$margin;
  }
}

@mixin twoColumnsLayout {
  @include containerLayout(0 24px, 0 24px);

  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  grid-gap: const.$margin * 1.25;

  @include responsive.ltXlDesktop {
    grid-gap: const.$margin;
    border-radius: 0;
  }

  @include responsive.ltDesktop {
    grid-template-columns: 1fr;
    grid-gap: const.$margin;
  }

  > div:not(.noPadding) {
    @include columnBoxContent;
  }
}

@mixin twoColumnsWithoutGapLayout {
  @include containerLayout(0, 0);
  @include columnBoxContent;

  display: grid;
  position: relative;
  padding: 0 24px;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0;

  @include responsive.ltXlDesktop {
    padding: 0 24px;
  }

  @include responsive.mobile {
    grid-template-columns: 1fr;
    padding: 0 24px;
  }

  > div:not(.noPadding) {
    padding: const.$margin * 2 const.$margin * 3;

    @include responsive.xlDesktop {
      &:nth-child(1) {
        padding-right: (const.$margin * 3) + 12px;
      }

      &:nth-child(2) {
        padding-left: (const.$margin * 3) + 12px;
      }
    }

    @include responsive.ltXlDesktop {
      padding: const.$margin * 2;
    }

    @include responsive.ltDesktop {
      padding: const.$margin * 2 const.$margin;
    }
  }
}

@mixin fullColumnLayout {
  @include containerLayout(0, 0);
  @include columnBoxContent;
  @include theme.apply(background-color, neutral-white);
}

@mixin onErrorStyles {
  &.error,
  &.errors,
  &[data-error='true'] {
    @include theme.apply(border-color, error-default);

    box-shadow: 0 0 11px rgb(255 0 0 / 0.25);
  }
}

@mixin onDisabledStyles {
  &.disabled,
  &[data-disabled='true'] {
    @include svgColor(foreground-disabled);
    @include theme.apply(border-color, foreground-disabled);
    @include theme.apply(background-color, neutral-grey-98);

    label,
    select {
      @include theme.apply(color, foreground-disabled);
    }
  }
}

@mixin cleanWebkitInput {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  input[type='number'] {
    appearance: textfield;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important; //stylelint-disable-line
  }
}

@mixin cleanWebkitInputDark {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px black inset !important; //stylelint-disable-line
    -webkit-text-fill-color: white;
  }
}

@mixin fieldBase {
  padding: const.$formInputPaddingTop const.$formInputPaddingLeft;
  border: solid const.$formInputBorderWidth;
  border-radius: const.$formInputRadius;
  position: relative;
  height: const.$formInputHeight;

  @include theme.apply(background-color, neutral-white);
  @include theme.apply(border-color, foreground-default);

  label {
    @include theme.apply(color, foreground-default);
    @include designSystem.textStyleHeadingSmall12;

    line-height: 15px;
  }
}

@mixin noAutofillStyle {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset !important; //stylelint-disable-line
  }
}

@mixin noAutofillStyleDark {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px black inset !important; //stylelint-disable-line
    -webkit-text-fill-color: white;
  }
}

@mixin scrollbars($size: 6px, $foreground-color: var(--colour-neutral-grey-90)) {
  scrollbar-color: $foreground-color auto;
  overscroll-behavior: contain;

  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background: transparent;
  }

  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color !important; // stylelint-disable-line
    border-radius: 5px;
  }
}

@mixin cssVarContrastColour($background-colour, $foreground-colour) {
  $colours: map.get(const.$allContrastColours, $background-colour);

  @if meta.type-of($colours) == list {
    @if list.index($colours, $foreground-colour) {
      color: map.get($colour, $foreground-colour);
    } @else {
      // fallback to first contrast aaa colour
      $coloursAAA: map.get(const.$contrastColoursAaa, $background-colour);
      @if meta.type-of($coloursAAA) == list {
        color: map.get($colour, list.nth($coloursAAA, 1));
      } @else {
        @include theme.apply(color, $foreground-colour);
      }
    }
  } @else {
    @include theme.apply(color, $foreground-colour);
  }
}

@mixin genericFontFormat {
  h1 {
    @include designSystem.textStyleHeadingTitle60;

    margin: 0 0 const.$margin;

    @include responsive.ltLargeDesktop {
      @include designSystem.textStyleHeadingTitle54;
    }

    @include responsive.ltDesktop {
      @include designSystem.textStyleHeadingLarge42;
    }

    @include responsive.mobile {
      @include designSystem.textStyleHeadingLarge36;
    }
  }

  h2 {
    @include designSystem.textStyleHeadingTitle54;

    margin: 0 0 const.$margin * 0.75;

    @include responsive.ltLargeDesktop {
      @include designSystem.textStyleHeadingLarge42;
    }

    @include responsive.ltDesktop {
      @include designSystem.textStyleHeadingLarge36;
    }

    @include responsive.mobile {
      @include designSystem.textStyleHeadingLarge30;
    }
  }

  h3 {
    @include designSystem.textStyleHeadingLarge42;

    margin: 0 0 const.$margin * 0.75;

    @include responsive.ltLargeDesktop {
      @include designSystem.textStyleHeadingLarge36;
    }

    @include responsive.ltDesktop {
      @include designSystem.textStyleHeadingLarge30;
    }

    @include responsive.mobile {
      @include designSystem.textStyleHeadingMedium26;
    }
  }

  h4 {
    @include designSystem.textStyleHeadingLarge36;

    margin: 0 0 const.$margin * 0.5;

    @include responsive.ltLargeDesktop {
      @include designSystem.textStyleHeadingLarge30;
    }

    @include responsive.ltDesktop {
      @include designSystem.textStyleHeadingMedium26;
    }

    @include responsive.mobile {
      @include designSystem.textStyleHeadingMedium24;
    }
  }

  h5 {
    @include designSystem.textStyleHeadingLarge30;

    margin: 0 0 const.$margin * 0.5;

    @include responsive.ltLargeDesktop {
      @include designSystem.textStyleHeadingMedium26;
    }

    @include responsive.ltDesktop {
      @include designSystem.textStyleHeadingMedium24;
    }

    @include responsive.mobile {
      @include designSystem.textStyleHeadingMedium21;
    }
  }

  p {
    @include designSystem.textStyleBodySmall18;

    margin: 0 0 const.$margin * 0.25;
  }

  li {
    @include designSystem.textStyleBodySmall18;
  }
}

@mixin standardPage {
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@mixin standardPageInner {
  width: 100%;
  padding: 80px;
  max-width: responsive.$maxContentWidth + (80px * 2);

  @include responsive.mobile {
    padding: 24px;
  }
}

@mixin tagText {
  text-align: center;

  @include designSystem.textStyleBodySmall16;
  @include theme.apply(color, brand-default);
}

@mixin headings {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @content;
  }
}

@mixin homepageHeroContainer {
  @include theme.apply(background-color, hero-colour);

  display: flex;
  position: relative;
  align-items: center;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  min-height: 700px;
  max-height: 900px;
  max-width: 1920px;
  overflow: hidden;

  @include responsive.ltDesktop {
    align-items: flex-start;
    height: 100svh;
    min-height: 600px;
  }

  .container {
    position: relative;
    margin: 0 auto;
    padding: 0 24px;
    width: 100%;
    max-width: const.$contentMaxWidth;

    @include responsive.ltDesktop {
      height: calc(100% - 170px);
    }

    .content {
      display: grid;
      grid-template-columns: 1fr 365px;
      grid-template-rows: 1fr;
      align-items: center;
      position: relative;
      padding: 0;
      width: 100%;
      height: 100%;

      @include responsive.ltMedium {
        grid-template-columns: inherit;
        grid-template-rows: inherit;
        align-items: inherit;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 136px 0 0;
      }

      @include responsive.smallHeightPhone {
        padding: 96px 0 0;
      }

      .slotsWrapper {
        padding: 36px 36px 36px 0;

        @include responsive.ltDesktop {
          padding: 0;
        }
      }

      .side {
        display: flex;
        position: relative;
        justify-content: center;
        gap: 16px;
        width: 100%;

        @include responsive.smallPhone {
          flex-direction: column;
        }

        > h2 {
          @include theme.apply(color, neutral-white);
          @include designSystem.textStyleHeadingMedium21;

          justify-content: center;
          display: none;

          @include responsive.smallPhone {
            display: inherit;
          }
        }

        .formWrapper {
          position: relative;
          padding: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;
        }

        .formContainer {
          @include theme.apply(background-color, neutral-white);
          @include theme.apply(color, foreground-default);

          padding: 32px;
          border-radius: const.$blockBorderRadius;
          display: flex;
          flex-direction: column;
          row-gap: 16px;
          width: 100%;
          max-width: 400px;

          @include responsive.ltDesktop {
            padding: 24px;
            max-width: 335px;
          }

          @include responsive.smallPhone {
            align-self: center;
          }

          @include responsive.smallHeightPhone {
            row-gap: 8px;
          }

          @include designSystem.effectShadowM;

          .or {
            @include designSystem.textStyleBodySmall14;
            @include theme.apply(color, foreground-muted);

            position: relative;
            text-align: center;

            &::before,
            &::after {
              content: '';
              display: block;
              position: absolute;
              left: 0;
              top: 50%;
              width: calc(50% - 20px);
              height: 1px;

              @include theme.apply(background-color, foreground-disabled);
            }

            &::after {
              left: calc(50% + 20px);
              right: 0;
              width: inherit;
            }
          }
        }
      }
    }
  }
}

@mixin vdpSectionHeading {
  @include designSystem.textStyleBodySmall18;
  @include theme.apply(color, foreground-muted);

  @include responsive.vdpSizeDecreaseMobile {
    @include designSystem.textStyleBodySmall16;
  }
}
