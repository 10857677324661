@use 'sass:list';
@use 'sass:map';

$font: var(--font-name, Helvetica), Helvetica, Arial, sans-serif;
$fontSize: 14px;
$demiBold: 600;
$headingFontWeight: 600;
$heavy: 900;
$bodyFontWeight: normal;
$medium: $demiBold;
$red: red;

$margin: 24px;
$marginMobile: 12px;
$contentMaxWidth: 1464px;
$maxSearchDirectoryWidth: 2152px;
$contentPadding: $margin * 3;
$favouritesMaxWidth: 1640px;
$vdpMaxWidth: 1648px;

$blockBorderRadius: 30px;
$blockBorderRadiusMobile: 24px;

$formInputBorderWidth: 2px;
$formInputHeight: 75px + $formInputBorderWidth * 2;
$formInputRadius: 8px;
$formInputPaddingLeft: 18px; // without border width
$formInputPaddingTop: 12px;

$inputPaddingLeft: 18px;
$inputBorder: 4px solid #222222;

$buttonheight: $formInputHeight; // set button height same as form input height
$buttonheightMobile: 60px;
$buttonheightMedium: 57px;
$buttonheightMediumMobile: 57px;
$buttonheightSmall: 57px;
$buttonheightSmallMobile: 57px;
$buttonInputBorderWidth: 3px;
$buttonRadius: 12px;
$cardRadius: 24px;
$smallCardRadius: 12px;

$buttonSmallHeight: 48px;
$iconButtonSize: 48px;
$smallIconSize: 36px;

$headerHeight: 96px;
$headerHeightMobile: 80px;
$headerHeightServicing: 80px;
$headerHeightServicingMobile: 72px;
$headerMenuPadding: 12px 16px;
$headerMenuRadius: 8px;
$panelMenuWidth: 298px;

$headerBreadcrumbHeight: 72px;
$headerBreadcrumbHeightMobile: 48px;

// Transitions, ei = ease-in
$e200: 200ms ease;
$ei300: 300ms ease-in;

$contrastColoursAaa: (
  green-025: (
    neutral-white,
  ),
  deep-blue-010: (
    strawberry-090,
    yellow-050,
    neutral-white,
  ),
  deep-blue-015: (
    strawberry-090,
    yellow-050,
    neutral-white,
  ),
  blue-050: (
    strawberry-090,
    yellow-050,
    neutral-white,
  ),
  strawberry-090: (
    deep-blue-010,
    deep-blue-015,
    blue-050,
  ),
  yellow-050: (
    deep-blue-010,
    deep-blue-015,
    blue-050,
  ),
  neutral-white: (
    green-025,
    deep-blue-010,
    deep-blue-015,
    blue-050,
  ),
);

$contrastColoursAa18: (
  green-025: (
    deep-blue-010,
    strawberry-090,
    yellow-050,
  ),
  deep-blue-010: (
    green-025,
  ),
  strawberry-090: (
    green-025,
  ),
  yellow-050: (
    green-025,
  ),
);

@function getcontrastcolors() {
  $keys: map.keys(map.merge($contrastColoursAaa, $contrastColoursAa18));
  $result: ();

  @each $key in $keys {
    $map: ();

    @if list.index(map.keys($contrastColoursAaa), $key) and list.index(map.keys($contrastColoursAa18), $key) {
      $map: map.merge(
        $map,
        (
          $key: list.join(map.get($contrastColoursAaa, $key), map.get($contrastColoursAa18, $key)),
        )
      );
    } @else {
      @if list.index(map.keys($contrastColoursAaa), $key) {
        $map: map.merge(
          $map,
          (
            $key: list.join((), map.get($contrastColoursAaa, $key)),
          )
        );
      }
      @if list.index(map.keys($contrastColoursAa18), $key) {
        $map: map.merge(
          $map,
          (
            $key: list.join((), map.get($contrastColoursAa18, $key)),
          )
        );
      }
    }
    $result: map.merge($result, $map);
  }

  @return $result;
}

$allContrastColours: getcontrastcolors();
