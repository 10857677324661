@use 'responsive';
@use 'designSystem';
@use 'theme';
@use 'mixins';

.slotHeadingWithIcon {
  @include theme.apply(color, foreground-default);
  @include designSystem.textStyleHeadingMedium24;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 8px;

  svg {
    @include theme.apply(color, action-default);

    height: 30px;
  }

  @include mixins.headings {
    strong {
      @include theme.apply(color, brand-default);
      @include theme.apply(font-weight, heading-weight);
    }
  }
}
