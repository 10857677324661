@use 'const';
@use 'designSystem';
@use 'mixins';
@use 'responsive';
@use 'theme';

.pills {
  position: absolute;
  bottom: 132px;
  left: calc((min(100vw, 1920px) - 1416px) / 2 + 80px);
  right: calc((min(100vw, 1920px) - 1416px) / 2 + 80px);

  @include responsive.ltLargeDesktop {
    left: 80px;
    right: 80px;
  }

  @include responsive.ltDesktop {
    bottom: 108px;
    left: 0;
    right: 0;
  }

  .pillsContainer {
    display: flex;
    position: relative;
    justify-content: center;
    overflow: hidden;

    .pillsDesktop {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 36px;
      width: max-content;

      @include responsive.ltDesktop {
        display: none;
      }

      span {
        @include theme.apply(color, neutral-white);
        @include designSystem.textStyleBodySmall18;

        display: flex;
        flex-direction: row;
        gap: 8px;

        @include responsive.ltDesktop {
          justify-content: center;
        }
      }
    }
  }
}
