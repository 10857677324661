@use 'const';
@use 'designSystem';
@use 'mixins';
@use 'responsive';
@use 'theme';

.scrollMore {
  @include theme.apply(color, neutral-white);
  @include designSystem.textStyleBodySmall14;

  display: block;
  position: absolute;
  padding-bottom: 36px;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;

  @include responsive.ltDesktop {
    padding-bottom: 52px;
    left: 72px;
    right: 72px;
  }

  &::after {
    @include theme.apply(background-color, neutral-white);

    content: '';
    display: block;
    position: absolute;
    left: calc(50% - 1px);
    bottom: 0;
    width: 2px;
    height: 24px;

    @include responsive.ltDesktop {
      height: 40px;
    }
  }
}
