@use 'theme';
@use 'designSystem';

.slotList {
  @include theme.apply(color, foreground-muted);
  @include designSystem.textStyleBodyMedium22;

  p {
    @include designSystem.textStyleBodyMedium22;
  }

  ul,
  ol {
    margin: 0;
  }
}
