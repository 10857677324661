@use 'responsive';
@use 'theme';
@use 'designSystem';

.homepageHero {
  @include theme.apply(background-color, neutral-white);

  padding: 44px 32px;
  display: flex;
  justify-content: center;

  // Variables
  $pillOffset: 32px;
  $mobilePillOffset: 12px;
  $imageOffset: 36px; // offset image from tile
  $mobileImageOffset: 45px;
  $height: 778px;
  $mobileHeight: 605px;
  $tileColour: #e0e2e5;

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1433px;
    height: $height;
    padding-top: $imageOffset;

    .left,
    .right {
      height: 100%;
      width: 50%;
      display: flex;
      align-items: center;
    }

    .left {
      min-width: 360px;

      .leftContent {
        width: 100%;
        max-width: 475px;
        padding-left: 32px;

        // Overriding default <Slot/> heading / paragraph styles
        p {
          @include designSystem.textStyleBodySmall18;
        }

        h2 {
          @include designSystem.textStyleHeadingTitle60;

          @include responsive.ltDesktop {
            font-size: 54px;
          }

          strong {
            @include theme.apply(color, brand-default);
            @include theme.apply(font-weight, heading-weight);
          }
        }

        h1 {
          @include designSystem.textStyleBodySmall18;
          @include theme.apply(color, neutral-grey-45);

          max-width: 365px;
        }

        @include responsive.ltDesktop {
          max-width: 360px;
          padding-left: 12px;
        }

        @include responsive.mobile {
          max-width: none;
          padding: 0 12px;
        }
      }
    }

    .right {
      justify-content: center;
      position: relative;
      padding: 0 $pillOffset;
      z-index: inherit;

      .pills {
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.default {
          span {
            &:nth-child(even) {
              align-self: flex-end;
              margin-top: 144px;
            }
          }
        }

        &.alignRight {
          gap: 48px;

          span {
            align-self: flex-end;

            &:nth-child(even) {
              margin-right: 80px;
            }
          }
        }

        span {
          @include designSystem.textStyleBodySmall18;
          @include designSystem.effectShadowM;

          padding: 24px 48px;
          border-radius: 40px;
          width: min-content;
          white-space: nowrap;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 8px;

          @include theme.apply(background-color, brand-default);
          @include theme.apply(color, neutral-white);

          &.reverse {
            @include theme.apply(color, brand-default);
            @include theme.apply(background-color, neutral-white);
          }

          svg {
            stroke-width: 1.5;
            width: 32px;
            height: 32px;
          }
        }

        @include responsive.mobile {
          &,
          &.default,
          &.alignRight {
            padding: 24px 0;
            gap: 12px;
            justify-content: flex-end;

            span {
              align-self: flex-start;

              &:nth-child(even) {
                align-self: flex-end;
                margin-right: 0;
                margin-top: 0;
              }
            }

            &.withLinkField {
              padding: 24px 0 96px;
            }
          }
        }
      }

      .tile {
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: flex-end;
        border-radius: 32px;
        background-color: $tileColour;

        .imageWrapper {
          overflow-x: hidden;
          border-radius: 32px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          width: 100%;
          height: calc(100% + $imageOffset);

          picture {
            width: initial;
            height: 100%;
            overflow: hidden;
          }

          .image {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: left top;

            @include responsive.mobile {
              height: 100%;
            }
          }

          @include responsive.mobile {
            margin-top: -$imageOffset;
            height: calc(100% + $mobileImageOffset);
          }
        }

        .link {
          @include designSystem.textStyleHeadingSmall18;
          @include theme.apply(color, foreground-default);
          @include theme.apply(border-color, foreground-default);

          position: absolute;
          bottom: 34px;
          right: 34px;
          display: flex;
          align-items: center;
          gap: 8px;
          background-color: $tileColour;
          border-radius: 32px;
          padding: 8px 16px;
          text-decoration: none;
          z-index: 2;

          svg {
            border: 2px solid;
            border-radius: 100%;
          }

          @include responsive.mobile {
            right: initial;
            left: 34px;
            flex-direction: row-reverse;
          }
        }
      }

      @include responsive.mobile {
        height: $mobileHeight;
        padding: $mobileImageOffset $mobilePillOffset 0;
      }
    }

    @include responsive.mobile {
      flex-direction: column;
      min-width: 0;
      height: 100%;
      gap: 24px;
      padding-top: 0;

      .left,
      .right {
        width: 100%;
        align-items: flex-start;
      }
    }
  }

  @include responsive.ltDesktop {
    padding: 44px 12px;
  }
}
