@use 'const';
@use 'responsive';
@use 'theme';
@use 'mixins';

$breakpointHeroWithForm: 1800px;

@mixin breakpointHeroWithForm {
  @media (max-width: $breakpointHeroWithForm) {
    @content;
  }
}

.heroWithVideo {
  @include theme.apply(background-color, neutral-white);

  .container {
    max-width: const.$contentMaxWidth;
    margin: 0 auto;
    padding: 0 24px;

    .content {
      border-radius: const.$blockBorderRadius;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      position: relative;
      padding: 0;
      align-items: center;

      @include responsive.mobile {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, auto);
      }

      .slotsWrapper {
        padding: 180px 152px 180px 0;

        @include breakpointHeroWithForm {
          padding: 126px 108px 320px 0;
        }

        @include responsive.ltXlDesktop {
          padding: 126px 64px 320px 0;
        }

        @include responsive.ltLargeDesktop {
          padding: 126px 48px 320px 0;
        }

        @include responsive.ltDesktop {
          padding: 52px 24px 160px 0;
        }

        @include responsive.mobile {
          padding: 48px 0 24px;
        }
      }

      .videoWrapper {
        position: absolute;
        top: 0;
        left: 50%;
        width: 50%;
        height: 100%;

        @include responsive.mobile {
          position: relative;
          left: inherit;
          width: 100%;
        }
      }

      .imageWrapper {
        display: none;
        margin-top: 24px;

        @include responsive.mobile {
          display: inherit;

          > div {
            aspect-ratio: unset;
          }
        }

        .image {
          width: 100%;
          height: auto;
          aspect-ratio: 10 / 8;
          object-fit: cover;
          object-position: center;
          border-radius: 30px;
        }
      }
    }
  }
}
