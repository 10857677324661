@use 'const';
@use 'responsive';
@use 'theme';
@use 'designSystem';

.notFoundLinks {
  @include theme.apply(background-color, neutral-white);

  border-radius: const.$blockBorderRadius;
  display: flex;
  flex-direction: column;
  gap: const.$margin;
  padding: 48px 60px;

  @include responsive.ltLargeDesktop {
    padding: 48px;
  }

  @include responsive.ltDesktop {
    padding: 48px 24px;
  }

  h3 {
    @include theme.apply(color, foreground-default);
    @include designSystem.textStyleHeadingLarge30;

    margin: 0;
  }

  ul {
    @include theme.apply(color, action-default);

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    list-style: none;
    margin: 0;
    padding: 0;

    @include responsive.ltLargeDesktop {
      grid-template-columns: repeat(3, 1fr);
    }

    @include responsive.ltDesktop {
      grid-template-columns: repeat(2, 1fr);
    }

    @include responsive.mobile {
      grid-template-columns: 1fr;
    }

    li {
      @include designSystem.textStyleBodySmall18;

      line-height: 1.5;
      margin: 0 0 8px;

      a {
        @include theme.apply(color, action-default);

        text-decoration: none;
      }
    }
  }
}
