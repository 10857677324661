@use 'responsive';
@use 'theme';
@use 'mixins';

.shopTotalCars {
  border: none;
  padding: 24px 68px;
  width: auto;

  @include theme.apply(background-color, foreground-default);
  @include theme.apply(border-color, foreground-default);

  &:hover {
    @include theme.apply(background-color, foreground-darken);
    @include theme.apply(border-color, foreground-darken);
  }

  &:disabled {
    @include theme.apply(background-color, foreground-disabled);
    @include theme.apply(border-color, foreground-disabled);
  }

  &:active {
    @include theme.apply(background-color, foreground-darkest);
    @include theme.apply(border-color, foreground-darkest);
  }

  &:focus {
    @include theme.apply(background-color, foreground-darken);
    @include theme.apply(border-color, foreground-lighten);
  }

  @include responsive.mobile {
    padding: 24px 0;
    width: 100%;
  }
}
