@use 'const';
@use 'responsive';
@use 'theme';
@use 'designSystem';

$breakpointHeroWithForm: 1800px;

@mixin breakpointHeroWithForm {
  @media (max-width: $breakpointHeroWithForm) {
    @content;
  }
}

.heroWithForm {
  @include theme.apply(background-color, neutral-white);

  .container {
    max-width: const.$contentMaxWidth;
    margin: 0 auto;
    padding: 0 24px;

    .content {
      border-radius: const.$blockBorderRadius;
      display: grid;
      grid-template-columns: 1fr 450px;
      grid-template-rows: 1fr;
      position: relative;
      padding: 132px 0;
      align-items: center;

      @include breakpointHeroWithForm {
        grid-template-columns: 1fr 600px;
        padding: 90px 0;
      }

      @include responsive.ltXlDesktop {
        grid-template-columns: 1fr 680px;
      }

      @include responsive.ltLargeDesktop {
        grid-template-columns: 460px 1fr;
        padding: 72px 0;
      }

      @include responsive.ltDesktop {
        grid-template-columns: 1fr 1fr;
        padding: 48px 0;
      }

      @include responsive.heroVerticalLayout {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, auto);
        gap: 24px;
        padding: 24px 0;
      }

      .slotsWrapper {
        padding: 48px 152px 48px 0;

        @include breakpointHeroWithForm {
          padding: 36px 108px 36px 0;
        }

        @include responsive.ltXlDesktop {
          padding: 36px 64px 36px 0;
        }

        @include responsive.ltLargeDesktop {
          padding: 36px 48px 36px 0;
        }

        @include responsive.ltDesktop {
          padding: 36px 24px 36px 0;
        }

        @include responsive.heroVerticalLayout {
          padding: 0;
        }
      }

      .side {
        position: relative;
        width: 100%;
        height: 100%;

        .formWrapper {
          position: relative;
          padding: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1;

          @include breakpointHeroWithForm {
            justify-content: left;
          }

          @include responsive.ltDesktop {
            padding-left: 24px;
          }

          @include responsive.heroVerticalLayout {
            padding-left: 0;

            > div {
              max-width: unset;
            }
          }

          @include responsive.mobile {
            justify-content: center;
          }
        }
      }

      .imageWrapper {
        position: absolute;
        height: 100%;
        top: 0;
        left: calc(100% - 225px);
        width: max-content;

        @include breakpointHeroWithForm {
          left: calc(100% - 375px);
        }

        @include responsive.ltXlDesktop {
          left: calc(100% - 455px);
        }

        @include responsive.ltLargeDesktop {
          left: 660px;
        }

        @include responsive.ltDesktop {
          display: none;
        }

        @include responsive.heroVerticalLayout {
          position: relative;
          display: inherit;
          left: inherit;
          width: inherit;
          border-radius: 30px;
          overflow: hidden;

          > div {
            aspect-ratio: unset;
          }
        }

        .image {
          width: auto;
          height: 100%;
          aspect-ratio: 1 / 1;
          object-fit: cover;
          object-position: center;

          @include responsive.largeDesktop {
            max-width: 512px;
          }

          @include responsive.heroVerticalLayout {
            aspect-ratio: 3 / 2;
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}
