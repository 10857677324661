@use 'responsive';
@use 'designSystem';
@use 'theme';
@use 'mixins';

.search {
  display: inline-flex;
  gap: 12px;
  width: 100%;

  .searchButton {
    width: auto;
    height: auto;
    padding: 16px 20px;
    border: none;
  }

  .inputWrapper {
    position: relative;

    input[type='text'] {
      padding: 16px;
      border: 2px solid;
      border-radius: 4px;
      width: 100%;
      height: 100%;
      transition: box-shadow 150ms;

      @include designSystem.textStyleBodySmall18;
      @include theme.apply(background-color, neutral-white);
      @include theme.apply(border-color, foreground-subtle);
      @include theme.apply(color, foreground-default);

      &:focus {
        @include designSystem.effectStyleCardHover();
      }

      @include responsive.ltDesktop {
        @include designSystem.textStyleBodySmall16;
      }

      @include responsive.mobile {
        border-radius: 12px;
      }
    }

    .clearButton {
      @include mixins.cleanButton();

      position: absolute;
      top: 0;
      bottom: 0;
      right: 14px;
    }
  }
}
