@use 'responsive';

.slotStaticButton {
  display: flex;
  width: 100%;

  &:not(.slotStaticLargeButton) {
    > a.slotStaticButtonStyle {
      width: 100%;
    }
  }

  &.slotStaticLargeButton {
    > a.slotStaticButtonStyle {
      width: auto;

      @include responsive.mobile {
        width: 100%;
      }
    }
  }
}
