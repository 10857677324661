@use 'const';
@use 'responsive';
@use 'theme';
@use 'mixins';

.homepageHeroWithForm {
  @include theme.apply(background-color, neutral-white);

  position: relative;
  overflow: hidden;

  .backgroundTile {
    @include theme.apply(background-color, brand-default);

    position: absolute;
    width: 50%;
    height: 100%;
    left: 50%;
    top: 0;

    @include responsive.mobile {
      display: none;
    }
  }

  .container {
    max-width: const.$contentMaxWidth + 64px * 2; // include outer padding
    margin: 0 auto;
    padding: 120px 64px 48px;

    .content {
      border-radius: const.$blockBorderRadius;
      display: grid;
      gap: 64px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      position: relative;
      align-items: center;

      .slotsWrapper {
        width: fit-content;
        padding-right: 185px - 32px;

        @include responsive.ltXlDesktop {
          padding-right: 64px - 32px;
        }

        @include responsive.ltDesktop {
          padding: 0 12px;
        }
      }

      .formWrapper {
        position: relative;
        padding: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        z-index: 1;

        @include responsive.mobile {
          justify-content: center;

          & > div {
            max-width: none;
          }
        }
      }

      .imageWrapper {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;

        @include responsive.ltDesktop {
          display: none;
        }

        .image {
          width: 100%;
          height: 100%;
          aspect-ratio: 1 / 1;
          object-fit: cover;
        }
      }

      @include responsive.mobile {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, auto);
        gap: 16px;
      }
    }

    @include responsive.ltDesktop {
      padding: 24px;
    }
  }

  .carImages {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 0;
    height: 400px;
    border-radius: 30px;

    > div {
      display: flex;
      flex-direction: row;
      width: max-content;
      height: 100%;

      > div {
        height: inherit;
        border-radius: 0;
      }

      img {
        height: 100%;
        object-fit: contain;
        object-position: bottom;
      }
    }

    @include responsive.ltXlDesktop {
      height: 320px;
    }

    @include responsive.ltDesktop {
      height: 200px;
    }

    @include responsive.mobile {
      @include theme.apply(background-color, brand-default);

      height: 260px;
      padding: 30px 0;
      margin: 0 24px;
      overflow: hidden;

      > div {
        img {
          object-position: center;
        }
      }
    }

    @include responsive.ltMobile {
      justify-content: flex-start;

      & > div {
        width: 100%;

        & > div {
          img {
            object-fit: cover;
            width: 100%;
          }

          &:nth-child(1) {
            flex: 0 0 20%;

            img {
              object-position: right;
            }
          }

          &:nth-child(2) {
            flex: 0 0 80%;

            img {
              object-position: left;
            }
          }

          &:only-child {
            flex: 0 0 100%;
            object-fit: contain;

            img {
              width: auto;
            }
          }
        }
      }
    }
  }

  @include responsive.mobile {
    @include theme.apply(background-color, neutral-white);
  }
}
