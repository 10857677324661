@use 'const';
@use 'responsive';

.featured {
  padding: 80px 0 0;

  @include responsive.mobile {
    padding: 48px 0 24px;
  }

  .featuredContainer {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 0;

    @include responsive.mobile {
      justify-content: flex-start;
      padding: 0 const.$margin;
      overflow: scroll;
    }

    > div {
      display: flex;
      flex-direction: row;
      gap: 40px;
      width: max-content;
    }
  }
}
