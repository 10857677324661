@use 'responsive';

.slotButtons {
  display: flex;
  flex-direction: row;
  column-gap: 32px;

  a {
    width: max-content;
  }

  @include responsive.ltDesktop {
    flex-wrap: wrap;
    row-gap: 16px;

    a {
      width: 100%;
    }
  }

  @include responsive.mobile {
    flex-direction: column;
    width: 100%;

    a {
      width: 100%;
    }
  }
}
