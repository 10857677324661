$tinyPhoneWidth: 380px !default;
$smallPhoneWidth: 480px !default;
$mobileWidth: 768px !default;
$mediumWidth: 992px !default;
$tabletWidth: 1024px !default;
$desktop: 1200px !default;
$largeDesktopWidth: 1464px !default;
$xlargeDesktopWidth: 1800px !default;
$maxContentWidth: 1440px;
$heroVerticalLayoutBreakpoint: 800px !default;
$vdpLayoutBreakpoint: 1120px !default;
$vdpRecentlyViewedPaginationBreakpoint: 1100px;

/* Helper mixins, mainly for internal use */
@mixin breakpoint($size, $type: max-width, $offset: 0) {
  @media (#{$type}: ($size - $offset)) {
    @content;
  }
}

@mixin maxWidthBreakpoint($size, $offset: 0) {
  @include breakpoint($size, max-width, $offset) {
    @content;
  }
}

@mixin minWidthBreakpoint($size, $offset: 0) {
  @include breakpoint($size, min-width, $offset) {
    @content;
  }
}

@mixin constrainedBreakpoint($type: width, $minSize: 0, $minOffset: 0, $maxSize: 0, $maxOffset: 0) {
  @media (min-#{$type}: ($minSize - $minOffset)) and (max-#{$type}: ($maxSize - $maxOffset)) {
    @content;
  }
}

/* Mixins to be used by the app */
@mixin tinyPhone {
  @include maxWidthBreakpoint($tinyPhoneWidth) {
    @content;
  }
}

@mixin smallPhone {
  @include maxWidthBreakpoint($smallPhoneWidth) {
    @content;
  }
}

@mixin gteSmallPhone {
  @include minWidthBreakpoint($smallPhoneWidth) {
    @content;
  }
}

@mixin ltMobile {
  @include maxWidthBreakpoint($mobileWidth, 1) {
    @content;
  }
}

@mixin mobile {
  @include maxWidthBreakpoint($mobileWidth) {
    @content;
  }
}

@mixin gteMobile {
  @include minWidthBreakpoint($mobileWidth) {
    @content;
  }
}

@mixin gtMobile {
  @include minWidthBreakpoint($mobileWidth, -1) {
    @content;
  }
}

@mixin tablet {
  @include constrainedBreakpoint(width, $mobileWidth, -1, $tabletWidth) {
    @content;
  }
}

@mixin ltDesktop {
  @include maxWidthBreakpoint($tabletWidth) {
    @content;
  }
}

@mixin ltMedium {
  @include maxWidthBreakpoint($mediumWidth, 1) {
    @content;
  }
}

@mixin lteMedium {
  @include maxWidthBreakpoint($mediumWidth) {
    @content;
  }
}

@mixin medium {
  @include minWidthBreakpoint($mediumWidth) {
    @content;
  }
}

@mixin desktop {
  @include minWidthBreakpoint($tabletWidth, -1) {
    @content;
  }
}

@mixin ltLargeDesktop {
  @include maxWidthBreakpoint($desktop) {
    @content;
  }
}

@mixin largeDesktop {
  @include minWidthBreakpoint($desktop) {
    @content;
  }
}

@mixin ltXlDesktop {
  @include maxWidthBreakpoint($largeDesktopWidth) {
    @content;
  }
}

@mixin xlDesktop {
  @include minWidthBreakpoint($largeDesktopWidth, -1) {
    @content;
  }
}

@mixin ltXXlDesktop {
  @include maxWidthBreakpoint($xlargeDesktopWidth) {
    @content;
  }
}

@mixin xxlDesktop {
  @include minWidthBreakpoint($xlargeDesktopWidth, -1) {
    @content;
  }
}

@mixin landscapeMobile {
  @media (max-width: 1023px) and (max-height: 767px) and (orientation: landscape) {
    @content;
  }
}

@mixin landscapeTablet {
  @media (max-width: 1440px) and (max-height: 960px) and (orientation: landscape) {
    @content;
  }
}

/* Mixins to be used hero modules, change to stack layout */
@mixin heroVerticalLayout {
  @include maxWidthBreakpoint($heroVerticalLayoutBreakpoint) {
    @content;
  }
}

@mixin smallHeightPhone {
  @media (max-height: 680px) {
    @content;
  }
}

@mixin vdpRecentlyViewedMobilePagination {
  @include maxWidthBreakpoint($vdpRecentlyViewedPaginationBreakpoint) {
    @content;
  }
}

/* Mixins to be used vdp page, change to stack layout */
@mixin vdpDesktopLayout {
  @include minWidthBreakpoint($vdpLayoutBreakpoint, -1) {
    @content;
  }
}

@mixin vdpMobileLayout {
  @include maxWidthBreakpoint($vdpLayoutBreakpoint) {
    @content;
  }
}

@mixin vdpSizeIncreaseMobile {
  @include minWidthBreakpoint(800px, -1) {
    @content;
  }
}

@mixin vdpSizeDecreaseMobile {
  @include maxWidthBreakpoint(800px) {
    @content;
  }
}
