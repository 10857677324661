@use 'responsive';
@use 'theme';
@use 'designSystem';

.slotParagraph {
  @include theme.apply(color, foreground-muted);
  @include designSystem.textStyleBodyMedium22;

  p {
    @include designSystem.textStyleBodyMedium22;

    @include responsive.ltLargeDesktop {
      @include designSystem.textStyleBodySmall18;
    }

    @include responsive.mobile {
      @include designSystem.textStyleBodyMedium22;
    }
  }
}
