@use 'const';
@use 'responsive';
@use 'theme';
@use 'designSystem';

.slotImage {
  border-radius: const.$blockBorderRadius;
  position: relative;
  overflow: hidden;

  .label {
    @include theme.apply(background-color, action-darken);
    @include theme.apply(color, neutral-white);
    @include designSystem.textStyleHeadingMedium24;

    position: absolute;
    top: 16px;
    left: 16px;
    padding: 5px 10px;

    @include responsive.mobile {
      @include designSystem.textStyleHeadingSmall18;

      max-width: calc(100vw - 80px);
    }
  }

  img {
    display: block;
    position: relative;
    object-position: center;
    object-fit: cover;
    aspect-ratio: 2.5 / 1;
    width: auto;
    height: 290px;

    @include responsive.mobile {
      max-width: calc(100vw - 48px);
    }
  }
}
