@use 'responsive';

.videoContainer {
  position: relative;
  width: 50vw;
  height: 100%;
  max-width: 720px;
  z-index: 0;

  @include responsive.mobile {
    border-radius: 30px;
    overflow: hidden;
    aspect-ratio: 3 / 2;
    width: 100%;
    height: auto;
    margin-bottom: 48px;
  }

  button {
    position: absolute;
    bottom: 16px;
    right: 16px;
    width: inherit;

    svg {
      margin-right: 6px;

      @include responsive.mobile {
        margin: 0;
      }
    }
  }

  > video {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include responsive.mobile {
      z-index: -1;

      + button {
        padding-left: 9px !important; // stylelint-disable-line
        padding-right: 9px !important; // stylelint-disable-line
        z-index: 5;

        span {
          display: none;
        }
      }
    }
  }
}
