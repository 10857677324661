@use 'const';
@use 'designSystem';
@use 'mixins';
@use 'responsive';
@use 'theme';

.heroLanding {
  @include theme.apply(background-color, action-light-default);

  display: grid;
  grid-template-columns: 1fr 480px;
  position: relative;
  align-items: center;
  margin: 0 auto;
  width: 100vw;
  height: 100vh;
  min-height: 700px;
  max-height: 900px;
  max-width: 1920px;
  overflow: hidden;

  @include responsive.tablet {
    grid-template-columns: 1fr 420px;
  }

  @include responsive.ltMedium {
    grid-template-columns: 1fr 360px;
  }

  @include responsive.mobile {
    height: max-content;
    min-height: 100vh;
    grid-template-columns: 1fr;
  }

  .container {
    position: relative;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    height: 100%;

    .content {
      @include theme.apply(background-color, neutral-white);
      @include theme.apply(color, foreground-default);

      border-radius: 16px 0 0 16px;
      position: relative;
      padding: 48px;
      width: 100%;
      height: 100%;

      @include responsive.tablet {
        padding: 48px 36px;
      }

      @include responsive.ltMedium {
        padding: 48px 24px;
      }

      @include responsive.mobile {
        border-radius: 0;
      }

      .side {
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: flex-start;
        justify-content: center;
        justify-content: space-between;
        width: 100%;
        height: 100%;

        .logo {
          display: flex;
          position: relative;
          height: 32px;

          > a {
            position: relative;

            img {
              max-height: 100%;
            }
          }
        }

        .formContainer {
          display: flex;
          flex-direction: column;
          row-gap: 16px;
          padding: 24px 0;
          width: 100%;

          h1,
          div[class*='slotParagraph'] p,
          div[class*='slotList'] ul {
            margin-bottom: 16px;
          }

          div[class*='slotList'] ul {
            padding-left: 24px;
          }

          div[class*='slotParagraph'] p,
          div[class*='slotList'] ul li,
          div[class*='slotList'] ul li p {
            @include theme.apply(color, foreground-default);
            @include designSystem.textStyleBodySmall18;
          }
        }
      }
    }
  }

  .copyRight {
    @include designSystem.textStyleBodySmall16;

    display: flex;
    text-decoration: none;
    align-items: center;

    @include theme.apply(color, foreground-default);

    a {
      @include theme.apply(color, foreground-default);

      text-decoration: underline;
    }
  }
}
