@use 'const';
@use 'designSystem';
@use 'mixins';
@use 'responsive';
@use 'theme';

.headlineBorder {
  position: absolute;
  top: 100%;
  bottom: 0;
  left: 0;
  width: 4px;

  @include theme.apply(background-color, brand-default);

  @include responsive.ltDesktop {
    display: none;
  }

  &.start {
    top: 0;
    transition: all 0.5s ease;
  }

  &.end {
    bottom: 100%;
    transition: all 0.5s ease;
  }
}
