@use 'const';
@use 'responsive';
@use 'theme';
@use 'designSystem';
@use 'mixins';

$iconWidth: 16px;
$iconHeight: 10px;

.select {
  @include mixins.svgColor(action-darken);

  position: relative;

  > .icon {
    display: flex;
    position: absolute;
    top: 0;
    right: const.$formInputPaddingLeft;
    bottom: 0;
    z-index: 1;
    align-items: center;
    pointer-events: none;

    &.hasLabel {
      top: 32px;
      bottom: 12px;
    }

    > svg {
      width: $iconWidth;
      height: $iconHeight;
      transition: opacity 300ms ease;
    }
  }

  &.disabled {
    @include mixins.svgColor(foreground-disabled);
  }

  label {
    @include theme.apply(color, foreground-default);
    @include designSystem.textStyleHeadingSmall12;

    position: absolute;
    padding-top: const.$formInputPaddingTop + const.$formInputBorderWidth;
    padding-left: const.$formInputPaddingLeft + const.$formInputBorderWidth;
    z-index: 1;

    &.disabled {
      @include theme.apply(color, foreground-disabled);
    }
  }

  .field {
    position: relative;
    width: 100%;
    height: const.$formInputHeight;
    padding: const.$formInputPaddingTop const.$formInputPaddingLeft + const.$formInputBorderWidth;
    border: solid const.$formInputBorderWidth;
    border-radius: const.$formInputRadius;
    appearance: none;

    @include theme.apply(color, foreground-default);
    @include theme.apply(background-color, neutral-white);
    @include theme.apply(border-color, foreground-default);
    @include designSystem.textStyleBodyMedium22;

    line-height: const.$formInputHeight - const.$formInputPaddingTop * 2 - const.$formInputBorderWidth * 2; // 24px padding, 4px border

    @include responsive.mobile {
      @include designSystem.textStyleBodySmall18;

      line-height: const.$formInputHeight - const.$formInputPaddingTop * 2 - const.$formInputBorderWidth * 2; // keep the line height
    }

    &.hasLabel {
      padding: 32px const.$formInputPaddingLeft const.$formInputPaddingTop;
      line-height: const.$formInputHeight - (32px + const.$formInputPaddingTop + const.$formInputBorderWidth * 2);
    }

    &.withoutBorder {
      @include designSystem.textStyleHeadingSmall18;

      background: designSystem.$colorTertiaryPageBg;
      border: none;
      border-radius: 0;
      font-weight: normal;
      line-height: const.$formInputHeight - const.$formInputPaddingTop * 2;

      &.hasLabel {
        line-height: const.$formInputHeight - (32px + const.$formInputPaddingTop + const.$formInputBorderWidth * 2);
      }
    }

    &:disabled {
      @include theme.apply(background-color, neutral-grey-98);
      @include theme.apply(border-color, foreground-disabled);
      @include theme.apply(color, foreground-disabled);
    }
  }

  &.error {
    @include mixins.svgColor(error-default);

    .field {
      @include theme.apply(border-color, error-default);
    }
  }
}
