@use 'const';
@use 'responsive';
@use 'theme';
@use 'designSystem';

.formBlock {
  @include theme.apply(background-color, neutral-white);
  @include theme.apply(color, foreground-default);

  padding: 40px;
  border-radius: const.$blockBorderRadius;
  display: flex;
  flex-direction: column;
  row-gap: 18px;
  width: 100%;
  max-width: 456px;

  @include responsive.ltDesktop {
    padding: 30px;
  }

  @include designSystem.effectShadowM;

  h2,
  h3,
  h4,
  h5,
  h6 {
    @include designSystem.textStyleHeadingLarge30;
  }

  p {
    @include designSystem.textStyleBodyMedium22;
  }

  svg {
    margin-left: 12px;
    width: 20px;
  }
}
