@use 'const';
@use 'designSystem';
@use 'responsive';
@use 'theme';
@use 'mixins';

$checkIconSize: 24px;

.checklist {
  @include theme.apply(color, foreground-muted);

  display: flex;
  flex-flow: column wrap;
  margin: 0;
  padding: 0;
  gap: const.$margin const.$margin * 2.5;

  @include responsive.ltXlDesktop {
    gap: const.$margin const.$margin * 1.5;
  }

  @include responsive.mobile {
    gap: const.$margin * 0.75;
  }

  &.inline {
    justify-content: center;
    gap: const.$margin const.$margin * 2.5;

    @include responsive.mobile {
      margin: 0;
      justify-content: flex-start;
      grid-gap: const.$margin * 0.5;
    }
  }

  &.horizontal {
    flex-direction: row;
  }

  &:not(.inline) {
    @include responsive.largeDesktop {
      .item {
        &:nth-child(2n + 1):last-child {
          grid-column: 1 / 3;
        }
      }
    }
  }

  .item {
    display: flex;
    line-height: $checkIconSize;
    align-items: center;

    @include designSystem.textStyleBodySmall18;

    @include responsive.mobile {
      flex-direction: row;
    }

    .check {
      @include mixins.svgColor(foreground-muted);

      display: flex;
      width: $checkIconSize;
      height: $checkIconSize;
      margin-right: 14px;
      align-items: center;
      justify-content: center;

      @include responsive.mobile {
        margin-bottom: auto;
      }
    }
  }
}
