@use 'responsive';
@use 'theme';
@use 'const';

// stylelint-disable
// DEPRECATED VARIABLES, Don't use these, instead implement @cssVar() with variables.scss
$colorPrimaryDark: var(--colour-foreground-default, rgba(0, 52, 75, 1));
$colorPrimaryBrand: var(--colour-brand-default, rgba(0, 128, 119, 1));
$colorSecondaryDividerLines: var(--colour-foreground-subtle, rgba(213, 221, 225, 1));
$colorSecondaryGrey: var(--colour-neutral-grey-75, rgba(187, 196, 202, 1));
$colorSecondaryMidGrey: var(--colour-neutral-grey-75, rgba(190, 191, 192, 1));
$colorSecondaryVeryLightGrey: var(--colour-neutral-grey-98, #fafafa);
$colorActionDarken: var(--colour-action-darken, rgb(0, 14, 204));
$colorActionLightest: var(--colour-action-lightest, rgb(229, 231, 255));
$colorBaseNeutralGrey: var(--colour-neutral-grey-90, rgb(229, 229, 230));
$colorTertiaryWhite: var(--colour-neutral-white, rgb(255, 255, 255));
$colorTertiaryPageBg: var(--colour-neutral-grey-95, rgba(244, 244, 245, 1));
$colorPrimaryHighlightHover: var(--colour-action-lighten, rgb(19, 118, 255, 1));
$colorErrorRed: var(--colour-error-default, #f80000);
$colorDisabled: var(--colour-foreground-disabled, rgba(193, 199, 204, 1));
$colorDivider: var(--colour-foreground-subtle, rgba(224, 226, 229, 1));
$colorForegroundMuted: var(--colour-foreground-muted, rgba(69, 97, 120, 1));
// these don't have a mapping to new variables
$colorSecondaryLightGreen: #c1e6de;
$colorSecondaryHighlightGreen: #38ffcf;
$colorSecondaryOrangeSherbert: #ffb169;
$colorSecondaryDarkGrey: #3c3d3e;
$colorSecondaryLightBlue: #e6ecf3;
$colorSecondaryLightLightBlue: #e5e7ff;
$colorSecondaryLightLightGreen: #dbf8f1;
$colorPrimaryDeepBlue: #003147;
// END DEPRECATED VARIABLES

// Set colour of mymoto rewards modules throughout all sites
$mymotoRewardsBrandColour: #e5fff9;
$mymotoRewardsItemColour: #008077;

//Available car colours
$carColors: (
  'grey': #b7b7b7,
  'blue': #242df2,
  'white': #ffffff,
  'silver': #c0c0c0,
  'red': #e90b33,
  'black': #000000,
  'orange': #ff440a,
  'green': #19b000,
  'brown': #964b00,
  'yellow': #ffd600,
  'purple': #6e0ee9,
  'pink': #f513ec,
);

@mixin textLetterSpacing($spacing: 0.3384px) {
  // default: 24px * 0.0141
  letter-spacing: calc(var(--font-letter-spacing) * $spacing);
}

@mixin textStyleHeadingTitle60 {
  font-family: const.$font;
  font-size: 60px;
  font-weight: const.$demiBold;
  line-height: 1.2;

  @include textLetterSpacing(0.3384px);
  @include theme.apply(font-weight, heading-weight);
}

@mixin textStyleHeadingTitle54 {
  font-family: const.$font;
  font-size: 54px;

  line-height: 1.25;

  @include textLetterSpacing(0.3384px);
  @include theme.apply(font-weight, heading-weight);
}

@mixin textStyleHeadingLarge42 {
  font-family: const.$font;
  font-size: 42px;
  line-height: 1.25;

  @include textLetterSpacing(0.3384px);
  @include theme.apply(font-weight, heading-weight);
}

@mixin textStyleHeadingLarge36 {
  font-family: const.$font;
  font-size: 36px;
  line-height: 1.2;

  @include textLetterSpacing(0.3384px);
  @include theme.apply(font-weight, heading-weight);
}

@mixin textStyleHeadingLarge30 {
  font-family: const.$font;
  font-size: 30px;
  line-height: 1.25;

  @include textLetterSpacing(0.5px);
  @include theme.apply(font-weight, heading-weight);
}

@mixin textStyleHeadingMedium26 {
  font-family: const.$font;
  font-size: 26px;
  line-height: 1.25;

  @include textLetterSpacing(0.3384px);
  @include theme.apply(font-weight, heading-weight);
}

@mixin textStyleHeadingMedium24 {
  font-family: const.$font;
  font-size: 24px;
  line-height: 1.25;

  @include textLetterSpacing(0.3384px);
  @include theme.apply(font-weight, heading-weight);
}

@mixin textStyleHeadingMedium21 {
  font-family: const.$font;
  font-size: 21px;
  line-height: 1.25;

  @include textLetterSpacing(0.25px);
  @include theme.apply(font-weight, heading-weight);
}

@mixin textStyleHeadingSmall18 {
  font-family: const.$font;
  font-size: 18px;
  line-height: 1.41;

  @include textLetterSpacing(0.2538px);
  @include theme.apply(font-weight, heading-weight);
}

@mixin textStyleHeadingSmall16 {
  font-family: const.$font;
  font-size: 16px;
  line-height: 1.3;

  @include textLetterSpacing(0.2538px);
  @include theme.apply(font-weight, heading-weight);
}

@mixin textStyleHeadingSmall14 {
  font-family: const.$font;
  font-size: 14px;
  line-height: 20px;

  @include textLetterSpacing(0.25px);
  @include theme.apply(font-weight, heading-weight);
}

@mixin textStyleHeadingSmall12 {
  font-family: const.$font;
  font-size: 12px;
  line-height: 16px;

  @include textLetterSpacing(0.25px);
  @include theme.apply(font-weight, heading-weight);
}

@mixin textStyleBodyLarge30 {
  font-family: const.$font;
  font-size: 30px;
  font-style: normal;
  line-height: 1.4;

  @include theme.apply(font-weight, body-weight);
}

@mixin textStyleBodyLarge24 {
  font-family: const.$font;
  font-size: 24px;
  font-style: normal;
  line-height: 1.5;

  @include theme.apply(font-weight, body-weight);
}

@mixin textStyleBodyMedium22 {
  font-family: const.$font;
  font-size: 22px;
  font-style: normal;
  line-height: 1.4;

  @include theme.apply(font-weight, body-weight);
}

@mixin textStyleBodySmall18 {
  font-family: const.$font;
  font-size: 18px;
  font-style: normal;
  line-height: 1.333;

  @include textLetterSpacing(0.3384px);
  @include theme.apply(font-weight, body-weight);
}

@mixin textStyleBodySmall18Lh18 {
  line-height: 1;
  @include textStyleBodySmall18;
}

@mixin textStyleBodySmall16 {
  font-family: const.$font;
  font-size: 16px;
  font-style: normal;
  line-height: 1.375;

  @include textLetterSpacing(0.25px);
  @include theme.apply(font-weight, body-weight);
}

@mixin textStyleBodySmall14 {
  font-family: const.$font;
  font-size: 14px;
  font-style: normal;
  line-height: 1.5;

  @include theme.apply(font-weight, body-weight);
}

@mixin textStyleBodySmall12 {
  font-family: const.$font;
  font-size: 12px;
  font-style: normal;
  line-height: 1.25;

  @include theme.apply(font-weight, body-weight);
}

@mixin styleFooterParentMenu {
  @include textStyleHeadingSmall16();
  line-height: 1.42;
}

@mixin styleFooterMenu {
  @include textStyleBodySmall16();
  line-height: 1.42;

  @include theme.apply(font-weight, body-weight);
}

@mixin styleHeadline {
  font-size: 128px;
  line-height: 158px;

  @include textLetterSpacing(1.8px);
  @include theme.apply(font-weight, heading-weight);

  @include responsive.ltDesktop {
    font-size: 96px;
    line-height: 118px;

    @include textLetterSpacing(1.35px);
  }

  @include responsive.mobile {
    font-size: 80px;
    line-height: 98px;

    @include textLetterSpacing(1.12px);
  }

  @media (max-width: 640px) {
    font-size: 64px;
    line-height: 64px;

    @include textLetterSpacing(0.9px);
  }

  @include responsive.smallPhone {
    font-size: 40px;
    line-height: 46px;

    @include textLetterSpacing(0.56px);
  }
}

@mixin styleHeadlineSmall {
  font-size: 80px;
  line-height: 100px;

  @include textLetterSpacing(1.128px);
  @include theme.apply(font-weight, heading-weight);

  @include responsive.ltDesktop {
    font-size: 64px;
    line-height: 80px;

    @include textLetterSpacing(0.9px);
  }

  @include responsive.mobile {
    font-size: 54px;
    line-height: 66px;

    @include textLetterSpacing(0.76px);
  }

  @media (max-width: 640px) {
    font-size: 40px;
    line-height: 50px;

    @include textLetterSpacing(0.56px);
  }
}

@mixin effectStyleCardHover {
  box-shadow: 0px 0px 32px rgba(34, 34, 34, 0.23);
}

@mixin effectShadowS {
  box-shadow: 0px 4px 24px rgba(48, 49, 51, 0.25), 0px 0px 1px rgba(48, 49, 51, 0.05);
}

@mixin effectShadowM {
  box-shadow: 0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 32px rgba(48, 49, 51, 0.25);
}

@mixin shadowXS {
  box-shadow: 0px 0px 8px 0px rgba(48, 49, 51, 0.2), 0px 0px 1px 0px rgba(48, 49, 51, 0.05);
}

@mixin shadowTheme80 {
  box-shadow: 0px 4px 16px 0px rgba(48, 49, 51, 0.1);
}

@mixin fadeMask($reverse: false, $offset: 0%) {
  @if $reverse {
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 1) $offset, rgba(0, 0, 0, 0));
  } @else {
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) $offset, rgba(0, 0, 0, 0));
  }
}

@mixin defaultBannerSizes {
  h1 {
    @include textStyleHeadingTitle60;

    @include responsive.mobile {
      @include textStyleHeadingLarge36;
      line-height: 1.45;
    }
  }

  h2 {
    @include textStyleHeadingTitle54;

    @include responsive.mobile {
      @include textStyleHeadingLarge30;
    }
  }

  h3 {
    @include textStyleHeadingLarge36;

    @include responsive.mobile {
      @include textStyleHeadingMedium24;
    }
  }

  h4 {
    @include textStyleHeadingLarge30;

    @include responsive.mobile {
      @include textStyleHeadingMedium21;
    }
  }

  h5 {
    @include textStyleHeadingMedium24;

    @include responsive.mobile {
      @include textStyleHeadingMedium21;
    }
  }

  h6 {
    @include textStyleHeadingMedium21;

    @include responsive.mobile {
      @include textStyleHeadingSmall18;
    }
  }

  p {
    @include textStyleBodyLarge24;

    @include responsive.mobile {
      @include textStyleBodySmall18;
    }

    strong {
      @include theme.apply(font-weight, heading-weight);
    }
  }
}

@mixin responsiveBannerSizes {
  h1 {
    @include responsive.ltXlDesktop {
      @include textStyleHeadingTitle54;
    }

    @include responsive.ltLargeDesktop {
      @include textStyleHeadingLarge36;
    }

    @include responsive.ltDesktop {
      @include textStyleHeadingLarge30;
    }

    @include responsive.mobile {
      @include textStyleHeadingLarge36;
      line-height: 1.45;
    }
  }

  h3 {
    @include responsive.ltXlDesktop {
      @include textStyleHeadingLarge30;
    }

    @include responsive.ltDesktop {
      @include textStyleHeadingMedium24;
    }
  }

  h5 {
    @include responsive.ltXlDesktop {
      @include textStyleBodyMedium22;
    }

    @include responsive.ltDesktop {
      @include textStyleHeadingMedium21;
    }
  }

  p {
    @include responsive.ltXlDesktop {
      @include textStyleBodyMedium22;
    }

    @include responsive.ltDesktop {
      @include textStyleBodySmall18;
    }
  }
}

@mixin defaultFontSizes {
  @include defaultBannerSizes;

  input,
  select,
  p {
    @include textStyleBodyMedium22;

    @include responsive.mobile {
      @include textStyleBodySmall18;
    }
  }

  button,
  a {
    @include textStyleHeadingSmall18;

    @include responsive.mobile {
      @include textStyleBodySmall18;
    }
  }
}
