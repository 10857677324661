@use 'const';
@use 'designSystem';
@use 'mixins';
@use 'responsive';
@use 'theme';

$carouselBorderButtonColour: rgba(#ffffff, 0.25);
$carouselButtonColour: white;

.carouselContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;

  .buttons {
    position: absolute;
    bottom: 132px;
    left: calc((min(100vw, 1920px) - responsive.$maxContentWidth) / 2 + 16px);
    right: calc((min(100vw, 1920px) - responsive.$maxContentWidth) / 2 + 16px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include responsive.ltXlDesktop {
      bottom: 96px;
      left: 24px;
      right: 24px;

      @include responsive.ltDesktop {
        display: grid;
        grid-template-columns: 1fr max-content;
        gap: 24px;
        left: 24px;
        right: 24px;
      }

      @include responsive.mobile {
        gap: 8px;
      }
    }
  }
}

.buttonsContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;
  overflow: hidden;

  @include responsive.mobile {
    gap: 8px;
  }

  .playButton {
    @include mixins.cleanButton;

    display: flex;
    padding: 0;
    border-style: solid;
    border-width: 2px;
    border-radius: 12px;
    border-color: $carouselBorderButtonColour;
    color: $carouselButtonColour;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    transition: background-color 0.2s ease;

    &[type='button'] {
      appearance: none;
    }

    &:hover,
    &:active {
      @include theme.apply(border-color, foreground-lightest);
      @include theme.apply(color, foreground-lightest);
    }

    &:focus {
      border-color: $carouselButtonColour;
      border-width: 4px;
      color: $carouselButtonColour;
    }
  }

  .slideButton {
    @include mixins.cleanButton;
    @include theme.apply(color, neutral-white);
    @include designSystem.textStyleBodySmall16;

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0 24px;
    border-bottom: 2px solid;
    border-color: $carouselBorderButtonColour;

    @include responsive.ltDesktop {
      padding: 0 16px;
      width: 100%;

      &:not(.slideButtonActive) {
        display: none;
      }
    }

    @include responsive.smallPhone {
      @include designSystem.textStyleHeadingSmall16;

      text-align: left;
      padding: 0;
    }

    .slideProgress {
      display: none;
    }

    &.slideButtonActive {
      .slideProgress {
        transition: width 500ms linear;
        background-color: $carouselButtonColour;
        display: block;
        position: absolute;
        bottom: -2px;
        left: 0;
        right: 0;
        height: 2px;
        max-width: 100%;
      }
    }
  }
}
