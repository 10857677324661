@use 'responsive';
@use 'designSystem';
@use 'theme';
@use 'mixins';

.slotHeading {
  @include theme.apply(color, foreground-default);
  @include designSystem.textStyleHeadingTitle54;

  @include mixins.headings {
    @include designSystem.textStyleHeadingTitle54;

    @include responsive.ltXlDesktop {
      @include designSystem.textStyleHeadingLarge42;
    }

    @include responsive.tinyPhone {
      @include designSystem.textStyleHeadingLarge36;
    }

    strong {
      @include theme.apply(color, brand-default);
      @include theme.apply(font-weight, heading-weight);
    }
  }
}
