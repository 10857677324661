@use 'const';
@use 'designSystem';
@use 'mixins';
@use 'responsive';
@use 'theme';

.carouselWrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  max-height: 100%;

  @include responsive.mobile {
    display: none;
  }

  .carouselContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 555px;

    @include responsive.tablet {
      height: 400px;
    }
  }

  .buttons {
    position: relative;
    left: 24px;
    right: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}

.buttonsContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-start;
  gap: 12px;
  overflow: hidden;

  .slideButton {
    @include mixins.cleanButton;
    @include theme.apply(background-color, neutral-white);

    border-radius: 100%;
    width: 8px;
    height: 8px;

    &.slideButtonActive {
      @include theme.apply(background-color, action-default);
    }
  }
}
