@use 'const';
@use 'designSystem';
@use 'mixins';
@use 'responsive';
@use 'theme';

.homepageHeroWithImage {
  @include mixins.homepageHeroContainer;

  &::before {
    background: radial-gradient(circle, var(--colour-hero-gradient-start) 0%, var(--colour-hero-gradient-end) 90%);
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 30%;
    right: 0;
    height: 100%;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 70% 100%);
  }

  .headlinesContainer {
    position: absolute;
    top: 0;
    left: 24px;
    right: 24px;
    padding-top: 120px;

    @include responsive.xlDesktop {
      left: calc((min(100vw, 1920px) - responsive.$maxContentWidth) / 2);
      right: calc((min(100vw, 1920px) - responsive.$maxContentWidth) / 2);
    }

    @include responsive.tinyPhone {
      padding-top: 100px;
    }
  }

  .imageContainer {
    position: absolute;
    top: 240px;
    left: 24px;
    right: 24px;
    height: 100%;
    z-index: 0;
    display: flex;
    align-items: flex-start;
    justify-content: center;

    @include responsive.xlDesktop {
      left: calc((min(100vw, 1920px) - responsive.$maxContentWidth) / 2);
      right: calc((min(100vw, 1920px) - responsive.$maxContentWidth) / 2);
    }

    @include responsive.ltDesktop {
      top: 210px;
    }

    @include responsive.ltMedium {
      left: 0;
      right: 0;
      min-width: 600px;
    }

    @include responsive.mobile {
      top: 175px;
      left: calc((min(100vw, 600px) - 600px) / 2);
    }

    @include responsive.tinyPhone {
      top: 150px;
    }

    .image {
      display: block;
      width: 100%;
      height: 420px;
      object-fit: contain;
      object-position: center;
      max-width: 100%;
      max-height: 100%;

      @include responsive.ltMedium {
        object-position: top center;
        object-fit: cover;
      }
    }
  }
}

[data-site='carplace'] {
  .headlinesContainer div div {
    /* stylelint-disable-next-line declaration-no-important */
    font-family: MuseoModerno, sans-serif !important;
    font-weight: bold;
  }

  .homepageHeroWithImage {
    background: conic-gradient(
      from 176deg at 50% 50%,
      #007972 126deg,
      #007972 126deg,
      #016573 127deg,
      #019472 307deg,
      #007972 307deg
    );

    &::before {
      background: none;
    }
  }
}
