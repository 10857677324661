@use 'const';
@use 'designSystem';
@use 'mixins';
@use 'responsive';
@use 'theme';

.search {
  display: flex;
  width: 100%;

  > button {
    display: none;
  }

  .inputWrapper {
    position: relative;

    input[type='text'] {
      padding: 16px;
      border: 2px solid;
      border-radius: 12px;
      width: 100%;
      height: 100%;
      transition: box-shadow 150ms;

      @include designSystem.textStyleBodySmall16;
      @include theme.apply(background-color, neutral-white);
      @include theme.apply(border-color, foreground-subtle);
      @include theme.apply(color, foreground-default);
    }

    > button {
      @include mixins.cleanButton();

      position: absolute;
      top: 0;
      bottom: 0;
      right: 14px;
    }
  }
}
