@use 'designSystem';
@use 'theme';
@use 'mixins';
@use 'responsive';

.container {
  width: 100%;
}

.autoComplete {
  width: 100%;
}

.suggestionsList {
  max-height: inherit;
  text-decoration: none;
  padding: 0;
  margin: 0;
  list-style: none;
}

.suggestionsContainer {
  transition: opacity 200ms, transform 100ms;

  &.suggestionsContainerOpen {
    overflow: scroll;
    border: 2px solid designSystem.$colorDivider;
    border-radius: 12px;
    z-index: 3;

    @include mixins.scrollbars();
    @include designSystem.effectStyleCardHover();
    @include theme.apply(background-color, neutral-white);

    .suggestionItems {
      display: block;
    }
  }

  &.bottom-start {
    transform-origin: top center;
  }

  .suggestionItems {
    display: none;

    .suggestion {
      @include designSystem.textStyleBodySmall16;
      @include theme.apply(color, foreground-default);

      text-transform: capitalize;
      list-style: none;
      padding: 16px;
      cursor: pointer;
      transition: background-color 500ms ease;

      &[data-disabled='true'],
      &.resultsHeading {
        @include theme.apply(color, foreground-muted);

        cursor: not-allowed;
      }

      &[aria-selected='true'] {
        @include theme.apply(background-color, action-lightest);
      }

      &:hover:not(&[data-disabled='true']) {
        @include theme.apply(background-color, action-lightest);
      }
    }
  }
}

.search {
  display: inline-flex;
  gap: 12px;
  width: 100%;

  .searchButton {
    width: auto;
    height: auto;
    padding: 16px 20px;
    border: none;
  }

  .inputWrapper {
    position: relative;

    input[type='text'] {
      padding: 16px;
      border: 2px solid;
      border-radius: 4px;
      width: 100%;
      height: 100%;
      transition: box-shadow 150ms;

      @include designSystem.textStyleBodySmall18;
      @include theme.apply(background-color, neutral-white);
      @include theme.apply(border-color, foreground-subtle);
      @include theme.apply(color, foreground-default);

      &:focus {
        @include designSystem.effectStyleCardHover();
      }

      @include responsive.ltDesktop {
        @include designSystem.textStyleBodySmall16;
      }

      @include responsive.mobile {
        border-radius: 12px;
      }
    }

    .clearButton {
      @include mixins.cleanButton();

      position: absolute;
      top: 0;
      bottom: 0;
      right: 14px;
    }
  }
}
