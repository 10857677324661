@use 'sass:map';
@use 'sass:meta';

@use 'const';

// WARNING: Don't add onto these variables without changing the documentation in @/styles/sites/README
// should only contain semantic colours
$semanticColour: (
  // foreground
  foreground-darkest: #001219,
  foreground-darken: #001e2b,
  foreground-default: #00344b,
  foreground-lighten: #184a68,
  foreground-lightest: #f3f4f5,
  foreground-disabled: #c1c7cc,
  foreground-muted: #456178,
  foreground-subtle: #e0e2e5,
  foreground-on-foreground: #ffffff,
  foreground-on-foreground-disabled: #456178,
  // neutral
  neutral-white: #ffffff,
  neutral-white-in-hex: #ffffff,
  neutral-grey-02: #f9f9f9,
  neutral-grey-05: #0d0d0d,
  neutral-grey-10: #222222,
  neutral-grey-20: #2f3037,
  neutral-grey-30: #464852,
  neutral-grey-35: #58595b,
  neutral-grey-45: #707275,
  neutral-grey-75: #bebfc0,
  neutral-grey-90: #e5e5e6,
  neutral-grey-95: #f4f4f5,
  neutral-grey-98: #fafafa,
  // brand
  brand-darkest: #002e33,
  brand-darken: #00454d,
  brand-default: #008077,
  brand-lighten: #00b086,
  brand-lightest: #e5fff9,
  brand-disabled: #c1c7cc,
  brand-on-brand: #ffffff,
  brand-on-brand-disabled: #00b086,
  // action
  action-darkest: #000a99,
  action-darken: #000ecc,
  action-default: #0011ff,
  action-lighten: #3341ff,
  action-lightest: #e5e7ff,
  action-disabled: #c1c7cc,
  action-on-action: #ffffff,
  action-on-action-disabled: #c1c7cc,
  // action light
  action-light-darkest: #6670ff,
  action-light-darken: #99a0ff,
  action-light-default: #cccfff,
  action-light-lighten: #e5e7ff,
  action-light-lightest: #ffffff,
  action-light-on-action-light: #0011ff,
  action-light-on-action-light-disabled: #3341ff,
  action-light-disabled: #c1c7cc,
  // success
  success-darkest: #002e33,
  success-darken: #00454d,
  success-default: #008077,
  success-lighten: #00b086,
  success-lightest: #ddfbef,
  success-disabled: #c1c7cc,
  success-on-success-disabled: #00b086,
  // warning
  warning-darkest: #665500,
  warning-darken: #ccaa00,
  warning-default: #ffd500,
  warning-lighten: #ffdd33,
  warning-lightest: #fffbe5,
  warning-disabled: #c1c7cc,
  warning-on-warning: #00344b,
  warning-on-warning-disabled: #ccaa00,
  // error
  error-darkest: #660000,
  error-darken: #990000,
  error-default: #cc0000,
  error-lighten: #ff002b,
  error-lightest: #ffe5e6,
  error-disabled: #c1c7cc,
  error-on-error: #ffffff,
  error-on-error-disabled: #ffffff,
  // accent-1
  accent-1-darkest: #665500,
  accent-1-darken: #ccaa00,
  accent-1-default: #ffd500,
  accent-1-lighten: #ffdd33,
  accent-1-lightest: #fffbe5,
  accent-1-disabled: #c1c7cc,
  accent-1-on-accent-1: #00344b,
  accent-1-on-accent-1-disabled: #ccaa00,
  // accent-2
  accent-2-darkest: #ff002b,
  accent-2-darken: #ff3333,
  accent-2-default: #ff9999,
  accent-2-lighten: #ffcccc,
  accent-2-lightest: #ffe5e6,
  accent-2-disabled: #c1c7cc,
  accent-2-on-accent-2: #00344b,
  accent-2-on-accent-2-disabled: #ffcccc,
  // info
  info-darkest: #222e58,
  info-darken: #2e3e75,
  info-default: #2541a7,
  info-lighten: #325bd4,
  info-lightest: #e8effc,
  info-disabled: #c1c7cc,
  info-on-info: #ffffff,
  info-on-info-disabled: #3341ff,
  // highlight
  highlight-default: #ccfff3,
  highlight-on-highlight: #00344b,
  // cool
  neutral-cool-50: #66798a,
  // gradient
  brand-default-or-gradient: #008077,
  // hero colours
  hero-colour: #008077,
  hero-gradient-start: rgba(255 255 255 / 0.25),
  hero-gradient-end: rgba(217 217 217 / 0)
);

$fonts: (
  heading-weight: const.$headingFontWeight,
  body-weight: const.$bodyFontWeight,
  medium-weight: const.$medium,
  demi-weight: const.$demiBold,
);

$props: (
  color: colour,
  background: colour,
  background-color: colour,
  fill: colour,
  stroke: colour,
  border-top-color: colour,
  border-bottom-color: colour,
  border-bottom: colour,
  border-left-color: colour,
  border-color: colour,
  accent-color: colour,
  font-weight: font,
);

$varMaps: (
  colour: $semanticColour,
  font: $fonts,
);

// the variable mixin takes a property and variable name
// and an optional override to hide the fallback
@mixin apply($prop, $var, $showFall: true) {
  // get the property's map name(s)
  $mapName: map.get($props, $prop);
  $nestName: null;
  $nestMapName: null;
  $map: null;
  $varFall: null;
  $varOutput: null;

  // if a list, we need to go deeper
  @if meta.type-of($mapName) == list {
    $nestName: list.nth($mapName, 1);
    $nestMapName: list.nth($mapName, 2);
  }

  // if it is a nested map
  @if $nestName {
    // get the map from nested map-name
    $map: map.get($var-maps, $nestName);

    // get the nested map
    $nestMap: map.get($map, $nestMapName);

    // fallback value, get the var value from the nested map
    $varFall: map.get($nestMap, $var);

    // our css4 variable output
    $varOutput: var(--#{$nestName}-#{$nestMapName}-#{$var});
  } @else {
    // get the map from map name
    $map: map.get($varMaps, $mapName);

    // fallback value, grab the variable's value from the map
    $varFall: map.get($map, $var);

    // our css4 variable output
    $varOutput: var(--#{$mapName}-#{$var});
  }

  // if show fallback is not overridden to be null
  @if $showFall {
    #{$prop}: $varFall;
  }

  // css4 variable output
  #{$prop}: $varOutput;
}
