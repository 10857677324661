@use 'const';
@use 'designSystem';
@use 'mixins';
@use 'responsive';
@use 'theme';

$playBorderButtonColour: rgba(#ffffff, 0.25);
$playButtonColour: white;

.videoContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  .videoOverlay {
    background-color: rgba(#2f3037, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .playButton {
    @include mixins.cleanButton;

    display: flex;
    padding: 0;
    border-style: solid;
    border-width: 2px;
    border-radius: 12px;
    border-color: $playBorderButtonColour;
    color: $playButtonColour;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 108px;
    right: calc((min(100vw, 1920px) - responsive.$maxContentWidth) / 2 + 16px);
    width: 48px;
    height: 48px;
    transition: background-color 0.2s ease;

    @include responsive.ltXlDesktop {
      right: 24px;
    }

    @include responsive.ltDesktop {
      bottom: 24px;
    }

    &[type='button'] {
      appearance: none;
    }

    &:hover,
    &:active {
      @include theme.apply(border-color, foreground-lightest);
      @include theme.apply(color, foreground-lightest);
    }

    &:focus {
      border-color: $playButtonColour;
      border-width: 4px;
      color: $playButtonColour;
    }
  }

  > video {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include responsive.mobile {
      z-index: -1;

      + button {
        padding-left: 9px !important; // stylelint-disable-line
        padding-right: 9px !important; // stylelint-disable-line
        z-index: 5;
      }
    }
  }
}
