@use 'const';
@use 'responsive';
@use 'theme';
@use 'designSystem';
@use 'mixins';

.notFoundPage {
  .banner {
    @include mixins.columnBoxContent;

    > div.bannerContent {
      @include designSystem.defaultBannerSizes;
      @include designSystem.responsiveBannerSizes;
      @include theme.apply(color, foreground-default);

      display: flex;
      flex-direction: column;
      gap: 80px;

      @include responsive.xlDesktop {
        padding-bottom: const.$margin;
      }

      @include responsive.ltDesktop {
        padding-top: 0;
        gap: 64px;
      }

      .notfoundText {
        text-align: center;

        @include responsive.ltDesktop {
          text-align: left;
        }

        h1 {
          @include designSystem.textStyleHeadingTitle54;

          margin-bottom: 32px;

          @include responsive.ltDesktop {
            @include designSystem.textStyleHeadingLarge42;
          }
        }

        p {
          @include designSystem.textStyleBodyLarge30;
          @include theme.apply(color, foreground-muted);

          @include responsive.mobile {
            @include designSystem.textStyleBodySmall18;
          }
        }
      }
    }
  }

  .breadcrumbs {
    @include theme.apply(background-color, neutral-white);
  }
}

.errorContainer {
  margin: const.$margin * 3 const.$margin;
  text-align: center;

  @include responsive.ltDesktop {
    margin: const.$margin * 2 const.$margin * 0.5;
  }

  h1 {
    @include designSystem.textStyleHeadingTitle54;

    margin: 0 0 const.$margin;

    @include responsive.ltDesktop {
      margin: 0 0 const.$margin * 0.5;
    }
  }

  h2 {
    @include designSystem.textStyleHeadingLarge36;
  }
}
