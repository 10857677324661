@use 'const';
@use 'designSystem';
@use 'responsive';
@use 'theme';
@use 'mixins';

.findRego {
  display: flex;
  flex-direction: column;
  min-width: 0;
  gap: 24px;

  .button {
    padding: 14px 33px 13px;
    height: auto;

    @include designSystem.textStyleHeadingSmall18;
  }

  .regoWrapper {
    display: grid;
    gap: 16px;

    .regoDesc {
      display: flex;
      gap: 16px;

      & > p {
        @include designSystem.textStyleBodySmall14;
        @include theme.apply(color, foreground-default);
      }

      svg {
        margin: 5px 0;
        width: inherit;
        flex-shrink: 0;
      }
    }
  }

  .regoDetails {
    & > p {
      @include theme.apply(color, foreground-default);
    }

    .itemTitle {
      @include designSystem.textStyleHeadingSmall16;
    }

    .itemDescription {
      @include designSystem.textStyleBodySmall16;
    }
  }

  .center {
    text-align: center;

    p,
    .stepSwap {
      @include designSystem.textStyleBodySmall18;
    }
  }

  .stepSwap {
    @include mixins.cleanButton;
    @include designSystem.textStyleBodySmall16;
    @include theme.apply(color, action-default);

    text-align: center;
    text-decoration: none;
  }
}

.regoFieldWrapped {
  display: grid;
  gap: 8px;

  .regoField {
    display: grid;
    grid-template-columns: 6.5fr 3.5fr;
    gap: 16px;
    overflow: hidden;

    svg {
      margin: 0;
      width: 20px;
      height: 20px;

      + p {
        font-size: 16px;
      }
    }
  }
}
